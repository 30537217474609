import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import BgImage from "../components/BgImage"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import MastWrap from "../components/MastWrap"
import PageHeading from "../components/PageHeading"

export default function Home({ data }) {
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 769px)`,
    },
  ]

  const PricingTable = styled.ul`
    padding: 0;
    margin: 0;
    line-height: 150%;
    font-size: 12px;
    padding-top: 10px;
  `

  const PricingTableHeading = styled.h2`
    text-transform: uppercase;
    padding: 5px 0;
    margin: 10px 0 1px 0;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    letter-spacing: 2px;
  `

  const PriceBlock = styled.li`
    text-align: center;
    width: 100%;
    color: #fff;
    float: left;
    list-style-type: none;
    transition: all 0.25s;
    position: relative;
    box-sizing: border-box;

    margin-bottom: 10px;
    border-bottom: 1px solid transparent;

    padding: 20px;

    :hover {
      transform: scale(1.04) translateY(-5px);
      z-index: 1;
      border-bottom: 0 none;
    }
  `

  const PriceBlockInner = styled.div`
    border: solid 2px #121212;
  `

  const Price = styled.div`
    display: table;
    width: 100%;
    height: 1rem;
  `

  const Products = styled.ul`
    padding: 0;
    margin: 0;
    color: #000;
  `

  const LineItem = styled.div`
    padding: 12px 15px;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    list-style-type: none;
    line-height: 24px;
  `

  const PriceFooter = styled.div`
    padding: 35px 15px;
  `

  return (
    <Layout>
      <MastWrap>
        <section>
          <div>
            <BgImage
              title="hero"
              fluid={sources}
              imgStyle={{ objectFit: "contain" }}
              height="100vh"
              overlayColor="#04040484"
            >
              <PageHeading heading="pricing" />
            </BgImage>
          </div>
        </section>

        <section className="sub-wrap pad-bottom white-bg">
          <div className="container-fluid">
            <div className="row">
              <article className="col-md-12">
                <PricingTable>
                  <PriceBlock>
                    <PriceBlockInner>
                      <PricingTableHeading className="font2 dark white-bg">
                        The Trial
                      </PricingTableHeading>
                      <Price className="dark-bg" />
                      <Products className="white-bg">
                        <LineItem>
                          Bridal makeup and hair styling
                          <br />
                          £150.00
                        </LineItem>
                        <LineItem>
                          Bridal makeup OR hair styling
                          <br />
                          £100.00
                        </LineItem>
                        <LineItem>
                          Bridesmaid makeup and hair styling
                          <br />
                          £90.00
                        </LineItem>
                        <LineItem>
                          Bridesmaid makeup OR hair styling
                          <br />
                          £70.00
                        </LineItem>
                        <LineItem>
                          Other bridal party/special occasion (eg Mother of
                          Bride; Prom Party)
                          <br />
                          Quote depending on hair length and style
                        </LineItem>
                      </Products>
                      <PriceFooter className="silver-bg">
                        <a
                          href="/contact"
                          className="btn btn-otto btn-otto-dark"
                        >
                          contact
                        </a>
                      </PriceFooter>
                    </PriceBlockInner>
                  </PriceBlock>

                  <PriceBlock>
                    <PriceBlockInner>
                      <PricingTableHeading className="font2 dark white-bg">
                        On The Day
                      </PricingTableHeading>
                      <Price className="dark-bg" />
                      <Products className="white-bg">
                        <LineItem>
                          Bridal makeup and hair styling
                          <br />
                          £150.00
                        </LineItem>
                        <LineItem>
                          Bridal makeup OR hair styling
                          <br />
                          £100.00
                        </LineItem>
                        <LineItem>
                          Bridesmaids makeup and hair styling
                          <br />
                          £90.00
                        </LineItem>
                        <LineItem>
                          Bridesmaids makeup OR hair styling
                          <br />
                          £70.00
                        </LineItem>
                        <LineItem>
                          Flower Girls (each)
                          <br />
                          £30.00
                        </LineItem>
                        <LineItem>
                          Other bridal party/special occasion (eg Mother of
                          Bride; Prom Party)
                          <br />
                          Quote depending on hair length and style
                        </LineItem>
                        <LineItem>
                          False Lashes (strip/individual)
                          <br />
                          £10.00
                        </LineItem>
                        <LineItem>
                          Hair extensions
                          <br />
                          POA
                        </LineItem>
                      </Products>
                      <PriceFooter className="silver-bg">
                        <a
                          href="/contact"
                          className="btn btn-otto btn-otto-dark"
                        >
                          Contact
                        </a>
                      </PriceFooter>
                    </PriceBlockInner>
                  </PriceBlock>

                  <PriceBlock>
                    <PriceBlockInner>
                      <PricingTableHeading className="font2 dark white-bg">
                        Other Costs
                      </PricingTableHeading>
                      <Price className="dark-bg" />
                      <Products className="white-bg">
                        <LineItem>
                          Travel – mileage (applicable after 10 miles from
                          postcode BR4)
                          <br />
                          £0.45 per mile
                        </LineItem>
                        <LineItem>
                          Minimum booking per wedding day (excluding cost of the
                          Trial)
                          <br />
                          £250.00
                        </LineItem>
                        <LineItem>
                          Provisional booking fee (fully redeemable against
                          confirmed booking)
                          <br />
                          £50.00
                        </LineItem>
                        <LineItem>
                          <em>
                            After the trial has taken place and you decide that
                            you would like to make a booking for your wedding
                            day or special occasion this deposit is then
                            deducted from your final package. You are under no
                            obligation to make a booking and, you only make the
                            booking after you are completely happy with your
                            Trial. Once a booking is confirmed, fees are
                            non-refundable upon cancellation. In the unlikely
                            event that I have to cancel your booking before a
                            Trial has taken place, your booking fee will be
                            refunded in full and I will help you to find another
                            makeup and hair stylist if needed.
                          </em>
                        </LineItem>
                      </Products>
                      <PriceFooter className="silver-bg">
                        <a
                          href="/contact"
                          className="btn btn-otto btn-otto-dark"
                        >
                          Contact
                        </a>
                      </PriceFooter>
                    </PriceBlockInner>
                  </PriceBlock>
                </PricingTable>
              </article>
            </div>
          </div>
        </section>
        <Footer />
      </MastWrap>
    </Layout>
  )
}

export const query = graphql`
  query Pricing {
    mobileImage: file(relativePath: { eq: "bg/14.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
    desktopImage: file(relativePath: { eq: "bg/14.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
          presentationWidth
        }
      }
    }
  }
`
